/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, FleetProxyConnectorSvcClient } from '@jarvis/web-stratus-client'

export const getProxiesList = async (
  authProvider,
  stack: Stack,
  page: number,
  size: number,
  hostHeader: string
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.getProxies(page, size, hostHeader)
  return response.data
}

export const removeProxiesList = async (
  proxyId: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.deleteProxy(proxyId)
  return response
}

export const updateProxy = async (
  proxyName: string,
  proxyId: string,
  description: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.updateProxy(proxyId, proxyName, description)
  return response
}

export const registerProxy = async (
  proxyName: string,
  proxyDescription: string,
  proxyHost: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.registerProxy(
    proxyName,
    proxyHost,
    proxyDescription
  )
  return response
}

export const validateUserCode = async (
  userCode: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.verifyUserCode(userCode)
  return response
}

export const approveUserCodeForPairing = async (
  userCode: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const stratus_user_token = await authProvider.getAccessToken()
  const response = await client.approveUserCode(
    userCode,
    stratus_user_token,
    'granted'
  )
  return response
}
