import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useShellRootContext } from '../../contexts/ShellRoot'
import ContextualFooter from '../../shared-components/ContextualFooter'
import { ActionSelect } from './styles'
import { Button, ThemeProvider } from '@veneer/core'
interface ContextualFooterProps {
  numberOfSelectedItems: number
  onCancel: () => void
  onRemove: () => void
  onUpdate: () => void
  isWexTheme?: boolean
}

const ProxyContextualFooter = ({
  numberOfSelectedItems,
  onCancel,
  onRemove,
  onUpdate,
  isWexTheme
}: ContextualFooterProps) => {
  const [selectValues, setSelectValues] = useState<Array<string>>([])
  const [disableContinue, setDisableContinue] = useState<boolean>(true)
  // Use the ShellRootContext to access the shell features.
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}

  useEffect(() => {
    selectValues.length === 0
      ? setDisableContinue(true)
      : setDisableContinue(false)
  }, [selectValues, numberOfSelectedItems])

  const getContextualFooterOptions = () => {
    return [
      {
        label: t('ecp-fleet-proxies.editLabel', 'Edit'),
        value: 'edit',
        disabled: numberOfSelectedItems > 1
      },
      {
        label: t('ecp-fleet-proxies.removeLabel', 'Remove'),
        value: 'remove',
        disabled: numberOfSelectedItems > 1
      }
    ]
  }

  const handleBtnAction = useCallback(() => {
    switch (selectValues[0]) {
      case 'remove':
        onRemove()
        break
      case 'edit':
        onUpdate()
        break
      default:
        // onDelete()
        break
    }
  }, [onRemove, onUpdate, selectValues])

  return (
    <ContextualFooter
      isWexTheme={isWexTheme}
      leadingArea={
        <>
          <Button
            appearance="secondary"
            data-testid="cancel-button"
            id="cancelBtn"
            onClick={() => onCancel()}
          >
            {t('ecp-fleet-proxies.cancel', 'Cancel')}
          </Button>

          <label data-testid="proxy-contextual-footer-items-label">
            {numberOfSelectedItems == 1
              ? numberOfSelectedItems +
                ' ' +
                t('ecp-fleet-proxies.itemSelected', 'item selected')
              : numberOfSelectedItems +
                ' ' +
                t('ecp-fleet-proxies.itemsSelected', 'items selected')}
          </label>
        </>
      }
      trailingArea={
        <>
          <ThemeProvider shape="round">
            <ActionSelect
              isWexTheme={isWexTheme}
              clearIcon={false}
              data-testid="select-footer-batch-operation"
              id="select-footer-batch-operation"
              label={
                selectValues
                  ? t('ecp-fleet-proxies.contextualAction', 'Select an Action')
                  : ''
              }
              onChange={({ value: v }) => {
                setSelectValues([v.toString()])
              }}
              options={getContextualFooterOptions()}
              placement="top"
              value={selectValues}
            />
          </ThemeProvider>

          <Button
            id="continue-btn"
            appearance="primary"
            onClick={() => handleBtnAction()}
            data-testid="confirm-batch-button"
            disabled={disableContinue}
          >
            {t('ecp-fleet-proxies.continue', 'Continue')}
          </Button>
        </>
      }
    />
  )
}

ProxyContextualFooter.propTypes = {
  numberOfSelectedItems: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedItems: PropTypes.array
}

export default ProxyContextualFooter
