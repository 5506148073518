import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const AddProxyModalFooter = styled.div`
  padding-top: ${primitives.layout.size5}px;
  label {
    margin-bottom: 15px;
  }
`
export const AddProxyModalDescr = styled.div`
  padding-top: ${primitives.layout.size5}px;
  span.hostName {
    font-weight: 700;
    margin: 0 5px;
  }
`
export const AddProxyModalNote = styled.div`
  padding-top: ${primitives.layout.size4}px;
`
export const RegProxyName = styled.div`
  font-weight: 800;
`
export const SuccessIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 21px;
  padding-right: 20px;
  padding-left: 20px;
`
export const StyledLink = styled.a`
  color: ${primitives.color.darkNavy7};
  text-decoration: none !important;
  line-height: ${primitives.typography.lineHeight2};
  padding: ${primitives.layout.size1}px;
  font-size: ${primitives.typography.size2};
}
`
export const DownloadLink = styled.a`
  color: ${primitives.color.hpBlue7};
  text-decoration: none !important;
  line-height: ${primitives.typography.lineHeight2};
  padding: ${primitives.layout.size1}px;
  font-size: ${primitives.typography.size2};
}
`
export const ProxyLink = styled.div`
  padding: ${primitives.layout.size5}px 0px ${primitives.layout.size5}px 0px;
`
export const ReconectProxy = styled.div`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
`
