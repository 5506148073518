import React from 'react'

import {
  ProxyContextualmenu,
  ProxyFooterContent,
  ProxyLeadingContent,
  ProxyTrailingContent
} from './styles'

const ContextualFooter = ({ leadingArea, trailingArea, isWexTheme }) => (
  <ProxyContextualmenu data-testid="proxy-footer-contextual-menu">
    <ProxyFooterContent
      data-testid="proxy-footer-content"
      isWexTheme={isWexTheme}
    >
      {leadingArea ? (
        <ProxyLeadingContent data-testid="proxy-leading-area">
          {leadingArea}
        </ProxyLeadingContent>
      ) : (
        <div></div>
      )}

      {trailingArea && (
        <ProxyTrailingContent data-testid="proxy-trailing-area">
          {trailingArea}
        </ProxyTrailingContent>
      )}
    </ProxyFooterContent>
  </ProxyContextualmenu>
)

export default ContextualFooter
