import React from 'react'
import { IconWarningAlt } from '@veneer/core'
import GenericModal from '../GenericModal'
import { RemoveModalContent } from './style'
import { useShellRootContext } from '../../../contexts/ShellRoot'

const RemoveModal = ({
  modalTitle,
  onClose,
  selectedItems,
  onDelete,
  showModal,
  isLoading,
  isWexTheme
}) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}
  return (
    <GenericModal
      dataTestId={'remove-proxy-modal'}
      id={'remove-proxy-modal'}
      onClose={onClose}
      show={showModal}
      customModalWidth={'612px'}
      customModalDescWidth={'327px'}
      header={{
        leadingIcon: (
          <IconWarningAlt
            data-testid="remove-proxy-modal-icon"
            color="darkOrange6"
            filled={true}
            size={48}
          />
        ),
        title: modalTitle,
        dataTestId: 'remove-modal-title'
      }}
      footer={{
        buttons: {
          cancel: {
            label: t('ecp-fleet-proxies.cancel', 'Cancel'),
            onClick: onClose,
            disabled: isLoading,
            dataTestId: 'remove-proxy-cancel-btn'
          },
          proceed: {
            colorScheme: 'negative',
            label: t('ecp-fleet-proxies.remove', 'Remove'),
            onClick: onDelete,
            disabled: isLoading,
            loading: isLoading,
            dataTestId: 'remove-proxy-btn'
          }
        }
      }}
    >
      <>
        <RemoveModalContent data-testid="remove-modal-content">
          {!isWexTheme &&
            t(
              'ecp-fleet-proxies.removeProxyDescr',
              'Are you sure you want to remove the selected proxies? All devices connected to these proxies will be removed from HP Command Center.'
            )}
          {isWexTheme &&
            t(
              'ecp-fleet-proxies.removeProxyDescrWx',
              'Are you sure you want to remove the selected proxies? All devices connected to these proxies will be removed from Workforce Experience Platform.'
            )}
        </RemoveModalContent>
        <RemoveModalContent data-testid="remove-proxy-name">
          {selectedItems.length > 0 &&
            (selectedItems.length === 1
              ? `${selectedItems[0].proxyName} (${selectedItems[0].devices} ${t(
                  'ecp-fleet-proxies.totaldevices',
                  'devices'
                )}` + `)`
              : `${selectedItems[0].proxyName} (${selectedItems[0].devices} ${t(
                  'ecp-fleet-proxies.totaldevices',
                  'devices'
                )} & ${selectedItems.length - 1} ${t(
                  'ecp-fleet-proxies.more',
                  'more'
                )} + `)}
        </RemoveModalContent>
        <RemoveModalContent data-testid="remove-proxy-confirmation-desc">
          {t('ecp-fleet-proxies.cannotBeDone', 'This action cannot be undone.')}
        </RemoveModalContent>
      </>
    </GenericModal>
  )
}

export default RemoveModal
