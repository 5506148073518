/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  UIEvent
} from 'react'
import {
  TableSortBy,
  SortTypes,
  PageSizeInterface,
  IconCircle,
  useToast,
  Table,
  IconXCircle,
  IconCheckmarkCircle,
  Tooltip
} from '@veneer/core'
import ContextualFooter from './contextualFooter'
import { ProxyList } from '../../interfaces/proxyInterface'
import {
  selectRow,
  filter,
  handleMultipleProxies,
  selectionState,
  getFormatedDate,
  jsonParse,
  sortTable,
  mockOnDeleteProxy,
  mockOnUpdatedProxy
} from '../../utils/commonMethods'
import {
  ProxyConstants,
  defaultOrderColumn,
  defaultpageSizeOptions,
  preferences
} from '../../utils/constants'
import Retrievei18nItems from './Retrievei18nItems'
import RemoveModal from '../../shared-components/Modals/RemoveModal'
import UpdateProxyModal from '../../shared-components/Modals/UpdateProxyModal'
import { removeProxiesList, updateProxy } from '../../utils/api'
import {
  ProxyStatus,
  ColumnPiece,
  ProxyName,
  ClickToAddProxy,
  HighlightedMark,
  WxStatus,
  StatusLabel
} from './styles'
import { useShellRootContext } from '../../contexts/ShellRoot'
import { ErrorWidget } from '@jarvis/react-portal-addons'
const defSortOrder: SortTypes = 'ascending'
const defSorting: TableSortBy = {
  id: 'pDateAdded',
  type: defSortOrder
}
const defSortingWX: TableSortBy = {
  id: 'pDateAdded',
  type: 'descending'
}
const ProxiesData = ({
  actionArea,
  proxies,
  searchProxyItem,
  searchProxyItems,
  isLoading,
  hasError,
  isWexTheme,
  retriveProxies,
  demoEnabled,
  userThemeMode
}) => {
  const proxiesTableColumns = 'proxyMFEColumns'

  const key = (subkey: string): string => {
    return t(
      `ecp-fleet-proxies.${subkey}`,
      subkey.charAt(0).toUpperCase() + subkey.slice(1)
    )
  }

  const getTableData = (
    name: string,
    dataTestId: string,
    searchKey: string
  ) => {
    const regex = new RegExp(`(${searchKey})`, 'gi')
    const parts = (name || '').split(regex)
    return (
      <ColumnPiece>
        <Tooltip
          content={name}
          contentHideDelayOnHover={300}
          contentShowDelayOnHover={300}
          data-testId={`${dataTestId}-tooltip`}
          id={`${dataTestId}-tooltip`}
          placement="bottom"
          portal
          useJsPositioning
        >
          <ProxyName data-testid={dataTestId}>
            {parts.map((part, index) =>
              part.toLowerCase() === searchKey.toLowerCase() ? (
                <HighlightedMark
                  isWexTheme={isWexTheme}
                  key={index}
                >
                  {part}
                </HighlightedMark>
              ) : (
                part
              )
            )}
          </ProxyName>
        </Tooltip>
      </ColumnPiece>
    )
  }

  const getStatusElement = (id: string, searchKey: string, status: string) => {
    if (isWexTheme) {
      return (
        <WxStatus
          status={status}
          userThemeMode={userThemeMode}
        >
          <ProxyStatus data-testid={`proxy-status-${id}`}>
            {status.toLowerCase() === 'online' && (
              <IconCheckmarkCircle
                color={userThemeMode === 'dark' ? '#212121' : '#ffffff'}
                data-testid="user-status-circle-online"
                filled
                size={14}
              />
            )}
            {status.toLowerCase() === 'offline' && (
              <IconXCircle
                color={userThemeMode === 'dark' ? '#212121' : '#ffffff'}
                data-testid="user-status-circle-offline"
                filled
                size={14}
              />
            )}
            <StatusLabel>
              {getTableData(
                key(status.toLowerCase()),
                `proxy-connectivity-status-${id}`,
                searchKey
              )}
            </StatusLabel>
          </ProxyStatus>
        </WxStatus>
      )
    } else {
      return (
        <ColumnPiece>
          <ProxyStatus data-testid={`proxy-status-${id}`}>
            <IconCircle
              data-testid="user-status-circle"
              color={getStatusIconColor(status.toLowerCase())}
              filled
              size={12}
            />
            <span>
              {getTableData(
                key(status.toLowerCase()),
                `proxy-connectivity-status-${id}`,
                searchKey
              )}
            </span>
          </ProxyStatus>
        </ColumnPiece>
      )
    }
  }

  const createProxyData = (proxyData: ProxyList[], searchKey: string) => {
    return proxyData.map((proxy, index) => ({
      ...proxy,
      name: getTableData(proxy.proxyName, `proxy-name-${proxy.id}`, searchKey),
      pName: proxy.proxyName || '-',
      proxyStatus: getStatusElement(
        proxy.id,
        searchKey,
        proxy.connectionState.status
      ),
      status: proxy.connectionState.status || '-',
      pDateAdded: getTableData(
        getFormatedDate(proxy.dateAdded, t, true),
        `proxy-date-added-${proxy.id}`,
        searchKey
      ),
      dateAdded: getFormatedDate(proxy.dateAdded) || '-',
      pLastUpdated:
        getTableData(
          getFormatedDate(proxy.lastUpdated, t, true),
          `proxy-last-updated-${proxy.id}`,
          searchKey
        ) || '-',
      lastUpdated: getFormatedDate(proxy.lastUpdated) || '-',
      pDescription: getTableData(
        proxy.description,
        `proxy-description-${proxy.id}`,
        searchKey
      ),
      description: proxy.description || '-',
      pDevices: getTableData(
        String(proxy.devices),
        `proxy-devices-${proxy.id}`,
        searchKey
      ),
      devices: proxy.devices,
      softwareVersion: proxy.softwareVersion || '-',
      sVersion: getTableData(
        proxy.softwareVersion,
        `proxy-version-${proxy.id}`,
        searchKey
      ),
      pHostName: getTableData(
        proxy.hostName,
        `proxy-host-name-${proxy.id}`,
        searchKey
      ),
      hostName: proxy.hostName || '-',
      proxyId: index,
      id: proxy.id,
      rowConfig: {
        selected: false,
        noWrap: true
      }
    }))
  }

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider || {}
  const { addToast } = useToast()
  const [currentPage, setCurrentPage] = useState<number>(
    ProxyConstants.currentPage
  )
  const [proxyData, setProxyData] = useState<ProxyList[]>([])
  const [proxyPageData, setProxyPageData] = useState<ProxyList[]>([])
  const [order, setOrder] = useState(
    jsonParse(sessionStorage.getItem(proxiesTableColumns), defaultOrderColumn)
  )
  const [totalItems, setTotalItems] = useState<number>(proxies?.length)
  const [pageSize, setPageSize] = useState<number>(ProxyConstants?.pageSize)
  const [orderBy, setOrderBy] = useState(isWexTheme ? defSortingWX : defSorting)
  const selectedItems: ProxyList[] = proxyData.filter(
    (data) => data[ProxyConstants?.rowConfig]?.selected
  )
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
  const numberOfSelectedItems: number = selectedItems?.length
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document
        .querySelector(
          `button[aria-label="${t('ecp-fleet-proxies.columnOptions', 'Column Options')}"]`
        )
        .setAttribute('data-testid', 'column-options')
      document
        .querySelector('button[aria-label="Select page size"]')
        .setAttribute('data-testid', 'select-page-size')
      document
        .querySelector('div[role="presentation"]')
        .setAttribute('data-testid', 'menu')
      document
        .querySelector(`input[type="checkbox"]`)
        .setAttribute('data-testid', 'checkbox')
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [t])
  type ColumnIndexTypes = 'visible' | 'hidden' | 'none'

  type proxyListColumnstype = {
    id: string
    label: string
    index?: ColumnIndexTypes
    required?: boolean
    sortable?: boolean
  }

  const proxyListColumns: Array<proxyListColumnstype> = [
    {
      id: 'name',
      label: t('ecp-fleet-proxies.proxyName', 'Proxy Name'),
      required: true
    },
    {
      id: 'proxyStatus',
      label: t('ecp-fleet-proxies.connectivity', 'Connectivity')
    },
    {
      id: 'pLastUpdated',
      label: t('ecp-fleet-proxies.lastUpdated', 'Last Updated')
    },
    {
      id: 'pDateAdded',
      label: t('ecp-fleet-proxies.dateAdded', 'Date Added')
    },

    {
      id: 'pDescription',
      label: t('ecp-fleet-proxies.description', 'Description')
    },

    {
      id: 'pDevices',
      label: t('ecp-fleet-proxies.devices', 'Devices')
    },
    {
      id: 'sVersion',
      label: t('ecp-fleet-proxies.fleetProxyVersion', 'Fleet Proxy Version')
    },
    {
      id: 'pHostName',
      label: t('ecp-fleet-proxies.hostName', 'Host Name')
    },
    {
      id: 'proxyId',
      label: 'proxyId',
      index: 'hidden'
    }
  ]

  const getStatusIconColor = (state) => {
    const colorState = {
      online: 'hunterGreen7',
      offline: 'red7'
    }

    return colorState[state?.toLowerCase()]
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (
    event: UIEvent<HTMLElement>,
    option: PageSizeInterface
  ) => {
    event.preventDefault()
    setPageSize(option.value)
  }

  useEffect(() => {
    const filterData = filter(proxyData, searchProxyItem, searchProxyItems)
    setTotalItems(filterData.length)
    const currentStartIndex = (currentPage - 1) * pageSize
    const startIndex =
      filterData.length > currentStartIndex ? currentStartIndex : 0
    const pageData: ProxyList[] = filterData.slice(
      startIndex,
      startIndex + pageSize
    )
    setProxyPageData(pageData)
    setTimeout(() => {
      handlePageChange(filterData.length > pageSize ? currentPage : 1)
    }, 100)
  }, [
    proxies,
    isLoading,
    searchProxyItem,
    proxyData,
    pageSize,
    searchProxyItems
  ])

  const setPaginationData = () => {
    const startIndex = (currentPage - 1) * pageSize
    const pageData: ProxyList[] = filter(
      proxyData,
      searchProxyItem,
      searchProxyItems
    ).slice(startIndex, startIndex + pageSize)
    setProxyPageData(pageData)
  }
  const sortableNames = {
    name: 'pName',
    proxyStatus: 'status',
    pDateAdded: 'dateAdded',
    pLastUpdated: 'lastUpdated',
    pDescription: 'description',
    pDevices: 'devices',
    pHostName: 'hostName',
    sVersion: 'softwareVersion'
  }
  useEffect(() => {
    const newId = sortableNames[orderBy.id] || orderBy.id
    const isColumnSortable = proxies.every(
      (proxy) => proxy[newId] !== undefined
    )
    if (!isColumnSortable) {
      return
    }
    const getProxyData = createProxyData(proxies, searchProxyItem).sort(
      (object1, object2) =>
        sortTable(
          object1,
          object2,
          newId,
          orderBy.type === 'descending' ? 1 : -1
        )
    )
    setProxyData(getProxyData)
  }, [proxies, isLoading, orderBy, searchProxyItem])

  useEffect(() => {
    const newId2 = sortableNames[orderBy.id] || orderBy.id
    setProxyPageData(
      proxyData.sort((object1, object2) =>
        sortTable(
          object1,
          object2,
          newId2,
          orderBy.type === 'descending' ? 1 : -1
        )
      )
    )
    setProxyPageData(proxyData)
    setPaginationData()
  }, [proxyData, isLoading, orderBy])

  useEffect(() => {
    setPaginationData()
  }, [currentPage, pageSize])

  const handleSelect = (event: React.ChangeEvent, index: number | string) => {
    setProxyPageData(
      selectRow(proxyData, event.target['checked'], index as number)
    )
    setPaginationData()
  }

  const handleSelectAllOrDeselectAll = (selected: boolean) => {
    handleMultipleProxies(proxyData, selected)
    setPaginationData()
  }

  const handleSelectAllPageItems = (event: React.ChangeEvent) => {
    setProxyPageData(
      handleMultipleProxies(proxyPageData, event.target['checked'])
    )
  }
  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(proxiesTableColumns, JSON.stringify(newOrder))
  }
  const handleSort = useCallback(
    (
      _: React.MouseEvent<HTMLElement, MouseEvent>,
      { id, type }: TableSortBy
    ) => {
      const regSorting = {
        id: id,
        type: type
      }
      setOrderBy(regSorting)
    },
    []
  )
  const openToast = useCallback(
    (action, itemName, selectedItems, error) => {
      const type = error ? 'negative' : 'positive'
      const id: string = error ? 'error-remove' : 'success'
      let text: string

      if (error) {
        text = 'Failed'
      } else {
        if (action === 'removeProxy') {
          text =
            itemName + ' ' + t('ecp-fleet-proxies.proxiesRemoved', ' removed.')
        } else if (action === 'updateProxy') {
          text =
            itemName +
            ' ' +
            t('ecp-fleet-proxies.successfullySaved', 'Successfully saved')
        } else {
          text = t('ecp-fleet-proxies.success', 'Success')
        }
      }
      addToast({
        id: id,
        type: type,
        text: text
      })
    },
    [addToast]
  )

  /* istanbul ignore next */
  const onDeleteProxy = useCallback(async () => {
    setModalLoading(true)
    try {
      if (isWexTheme && demoEnabled) {
        mockOnDeleteProxy(
          proxyData,
          selectedItems,
          setProxyData,
          openToast,
          setShowDeleteModal
        )
      } else {
        const deletedProxy = await removeProxiesList(
          selectedItems[0].id,
          authProvider,
          authStack
        )
        if (deletedProxy) {
          openToast(
            'removeProxy',
            selectedItems[0].proxyName,
            selectedItems.length,
            false
          )
          setShowDeleteModal(false)
        }
      }
    } catch (error) {
      openToast('removeProxy', '', selectedItems.length, true)
    } finally {
      !demoEnabled && retriveProxies()
      setModalLoading(false)
    }
  }, [selectedItems, openToast, retriveProxies])

  /* istanbul ignore next */
  const onUpdatedProxy = useCallback(
    async (proxyName: string, description: string) => {
      setModalLoading(true)
      try {
        if (isWexTheme && demoEnabled) {
          mockOnUpdatedProxy(
            proxyData,
            selectedItems,
            getTableData,
            proxyName,
            description,
            setProxyData,
            openToast,
            setShowUpdateModal
          )
        } else {
          const updatedProxy = await updateProxy(
            proxyName,
            selectedItems[0].id,
            description,
            authProvider,
            authStack
          )
          if (updatedProxy) {
            openToast('updateProxy', proxyName, selectedItems.length, false)
          }
          setShowUpdateModal(false)
        }
      } catch (error) {
        openToast('updateProxy', '', selectedItems?.length, true)
      } finally {
        !demoEnabled && retriveProxies()
        setModalLoading(false)
      }
    },
    [selectedItems, openToast, retriveProxies]
  )

  const customNoItems = useMemo(() => {
    const noItemsMessage = hasError
      ? t('ecp-fleet-proxies.unableToLoad', 'Unable to Load Data')
      : proxies.length === 0
        ? t('ecp-fleet-proxies.noProxies', 'No Proxies Found.')
        : t(
            'ecp-fleet-proxies.noResultsFound',
            'No Results Found in Proxies List'
          )

    const clickToAddMessage = hasError
      ? null
      : proxies.length === 0
        ? t(
            'ecp-fleet-proxies.clickToAddProxies',
            'Select the Add button to add a proxy.'
          )
        : t(
            'ecp-fleet-proxies.selectOtherFilters',
            'You can select other filters or adjust your search keywords.'
          )

    return (
      <div
        data-testid={
          hasError ? 'error-proxies-list' : 'error-empty-proxies-list'
        }
      >
        {noItemsMessage === 'Unable to Load Data' ? (
          <ErrorWidget
            message={noItemsMessage}
            onRetry={retriveProxies}
            labelRetry={t('ecp-fleet-proxies.retry', 'Retry')}
          />
        ) : (
          <ErrorWidget message={noItemsMessage} />
        )}
        {clickToAddMessage && (
          <ClickToAddProxy>{clickToAddMessage}</ClickToAddProxy>
        )}
      </div>
    )
  }, [hasError, proxies.length, t])
  const TableData = hasError ? [] : proxyPageData
  return (
    <>
      <Table
        actionArea={actionArea}
        data-testid="proxys-data-table"
        className="proxy-table-br"
        columns={proxyListColumns}
        columnReorder
        data={TableData}
        onSort={handleSort}
        preferences={{
          ...preferences,
          headerNoWrap: true,
          defaultOrder: defaultOrderColumn,
          order,
          sortBy: orderBy
        }}
        onColumnReorder={handleOnColumnReorder}
        loading={isLoading}
        loadingDataLength={5}
        pagination={{
          currentPage: currentPage,
          onPageChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange,
          pageSize: pageSize,
          pageSizeOptions: defaultpageSizeOptions,
          popoverPlacement: 'top-start',
          totalItems
        }}
        rowSelector={'multiSelection'}
        onSelect={handleSelect}
        onSelectAllPageItems={handleSelectAllPageItems}
        rowSelectAllState={selectionState(proxyPageData)}
        component={'table'}
        customNoItems={customNoItems}
        i18n={Retrievei18nItems()}
      />
      <RemoveModal
        data-testid="remove-proxy-modal"
        modalTitle={
          numberOfSelectedItems > 1
            ? t('ecp-fleet-proxies.removeProxies', 'Remove Proxies')
            : t('ecp-fleet-proxies.removeProxy', 'Remove Proxy')
        }
        onDelete={onDeleteProxy}
        selectedItems={selectedItems}
        onClose={() => {
          handleSelectAllOrDeselectAll(false)
          !demoEnabled && retriveProxies()
          setShowDeleteModal(false)
        }}
        showModal={showDeleteModal}
        isLoading={modalLoading}
        isWexTheme={isWexTheme}
      />
      {numberOfSelectedItems > 0 && (
        <UpdateProxyModal
          selectedProxyName={selectedItems[0].proxyName}
          selectedProxyDescr={selectedItems[0].description}
          data-testid="update-proxy-modal"
          modalTitle={t('ecp-fleet-proxies.editProxy', 'Edit a Proxy')}
          onUpdateProxy={onUpdatedProxy}
          onClose={() => {
            handleSelectAllOrDeselectAll(false)
            !demoEnabled && retriveProxies()
            setShowUpdateModal(false)
          }}
          showModal={showUpdateModal}
          isLoading={modalLoading}
          proxies={proxies}
        />
      )}
      {numberOfSelectedItems > 0 && (
        <ContextualFooter
          isWexTheme={isWexTheme}
          data-testid="proxies-list-contextual-footer"
          onCancel={() => {
            handleSelectAllOrDeselectAll(false)
          }}
          numberOfSelectedItems={numberOfSelectedItems}
          selectedItems={selectedItems}
          onRemove={() => {
            setShowDeleteModal(true)
          }}
          onUpdate={() => {
            setShowUpdateModal(true)
          }}
        />
      )}
    </>
  )
}

export default ProxiesData
