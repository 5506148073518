import React, { useState } from 'react'
import GenericModal from '../GenericModal'
import { TextBox, IconCheckmarkCircle } from '@veneer/core'
import {
  AddProxyModalDescr,
  AddProxyModalFooter,
  AddProxyModalNote,
  DownloadLink,
  ProxyLink,
  ReconectProxy,
  StyledLink,
  SuccessIconStyle
} from './style'
import { useShellRootContext } from '../../../contexts/ShellRoot'

import dayjs from 'dayjs'
import { validateUserCode } from '../../../utils/api'
import { ThemeProvider } from '@veneer/core'
import {
  getDownloadUrl,
  validateCodeForDemoMode
} from 'src/utils/commonMethods'

const AddProxyModal = ({
  modalTitle,
  onClose,
  onAddProxy,
  showModal,
  isLoading,
  proxyVerify,
  proxyConnect,
  proxies,
  userCode,
  isProxyRegisteredAndConnect,
  proxyRegistrationAndConnectionStatus,
  isWexTheme,
  demoEnabled,
  apiPath,
  requestOptions
}) => {
  const [proxyCode, setProxyCode] = useState<string>(userCode)
  const [proxyVerified, setProxyVerified] = useState<boolean>(proxyVerify)
  const [proxyConnected, setProxyConnected] = useState<boolean>(proxyConnect)
  const [hostName, setHostName] = useState<string>('')
  const [pinCode, setPinCode] = useState<string>('')
  const [proxyNameLatest, setProxyNameLatest] = useState<string>('')
  const [isProxyAvailable, setIsProxyAvailable] = useState<boolean>(false)
  const [isProxyRegisteredAndConnected, setIsProxyRegisteredAndConnected] =
    useState<boolean>(isProxyRegisteredAndConnect)
  const [proxyDescription, setProxyDescription] = useState<string>()
  const [hasError, setHasError] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(isLoading)

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const authProvider = interfaces?.v2.authProvider || {}
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const pathname = window.location.href

  /* istanbul ignore next */
  const verifyProxyCode = async () => {
    if (proxyCode === '' || proxyCode === null) {
      setHasError(true)
    } else {
      setButtonLoading(true)
      try {
        let resp = {
          data: {
            user_code: '',
            status: '',
            device_info: ''
          }
        }
        if (isWexTheme && demoEnabled) {
          resp = await validateCodeForDemoMode(apiPath, requestOptions)
        } else {
          resp = await validateUserCode(proxyCode, authProvider, authStack)
        }
        setPinCode(resp?.data?.user_code)
        setHostName(resp?.data?.device_info)
        setProxyNameLatest(resp?.data?.device_info + '_' + dayjs(new Date()))
        setProxyVerified(true)
        setProxyCode('')
      } catch (error) {
        setHasError(true)
      } finally {
        setButtonLoading(false)
      }
    }
  }

  /* istanbul ignore next */
  const verifyProxyHost = async () => {
    const res = proxies.filter(
      (it) => it.hostName.toLowerCase() === hostName.toLowerCase()
    )
    if (res && res.length > 0) {
      setProxyNameLatest(res[0].proxyName)
      setProxyDescription(res[0].description)
      setIsProxyRegisteredAndConnected(true)
    } else {
      setHasError(false)
      setProxyConnected(true)
      setIsProxyRegisteredAndConnected(false)
    }
  }
  /* istanbul ignore next */
  const handleContinueClick = async () => {
    if (!proxyVerified) {
      verifyProxyCode()
    } else {
      setButtonLoading(true)
      if (proxyConnected) {
        if (proxyNameLatest !== '') {
          const res = proxies.filter(
            (it) => it.proxyName.toLowerCase() === proxyNameLatest.toLowerCase()
          )
          if (res && res.length > 0) {
            setHasError(true)
            setButtonLoading(false)
            setIsProxyAvailable(true)
          } else {
            await onAddProxy(
              proxyNameLatest,
              proxyDescription,
              hostName,
              pinCode
            )
            setProxyVerified(false)
            setProxyConnected(false)
            setProxyNameLatest(hostName + '_' + dayjs(new Date()))
            setProxyDescription('')
            setIsProxyAvailable(false)
            setHasError(false)
            setButtonLoading(false)
          }
        } else {
          setHasError(true)
          setButtonLoading(false)
        }
      } else {
        setButtonLoading(true)
        await verifyProxyHost()
        if (isProxyRegisteredAndConnected) {
          await proxyRegistrationAndConnectionStatus(proxyNameLatest, pinCode)
          setProxyVerified(false)
          setProxyConnected(false)
          setIsProxyAvailable(false)
          setHasError(false)
        }
        setButtonLoading(false)
      }
    }
  }

  /* istanbul ignore next */
  const handleHelperText = () => {
    if (hasError) {
      if (proxyConnected) {
        if (proxyNameLatest === '') {
          return t('ecp-fleet-proxies.nameRequired', 'Proxy name is required.')
        } else if (isProxyAvailable) {
          return t(
            'ecp-fleet-proxies.proxyNameAvailable',
            "The proxy name you've entered is already in use. Enter a different name."
          )
        } else {
          setHasError(false)
        }
      } else {
        if (proxyCode === '' || proxyCode === null) {
          return t('ecp-fleet-proxies.codeRequired', 'Code is required.')
        } else {
          return t(
            'ecp-fleet-proxies.invalidCode',
            'Invalid code. Check the code on the HP Fleet Proxy and then try again.'
          )
        }
      }
    }
  }

  const handleModalTitle = () => {
    if (proxyVerified) {
      if (proxyConnected) {
        return t('ecp-fleet-proxies.proxyConnected', 'Proxy Connected')
      } else if (isProxyRegisteredAndConnected) {
        return t('ecp-fleet-proxies.reconnectProxy', 'Reconnect this proxy?')
      } else {
        return t('ecp-fleet-proxies.proxyVerified', 'Proxy Verified')
      }
    } else {
      return modalTitle
    }
  }

  const proceedBtnLabel = () => {
    if (proxyVerified) {
      if (proxyConnected) {
        return t('ecp-fleet-proxies.done', 'Done')
      } else if (isProxyRegisteredAndConnected) {
        return t('ecp-fleet-proxies.Reconnect', 'Reconnect')
      } else {
        return t('ecp-fleet-proxies.allow', 'Allow')
      }
    } else {
      return t('ecp-fleet-proxies.verify', 'Verify')
    }
  }

  const headerDescription = () => {
    if (proxyVerified) {
      if (proxyConnected || isProxyRegisteredAndConnected) {
        return (
          <>
            {proxyConnected && (
              <SuccessIconStyle>
                <IconCheckmarkCircle
                  data-test-id="success-icon"
                  size={53}
                  color="green6"
                />
              </SuccessIconStyle>
            )}

            {isProxyRegisteredAndConnected && (
              <>
                <ReconectProxy data-testid="registered-proxy-desc">
                  {t('ecp-fleet-proxies.proxyDescr', 'This proxy')}
                  <span
                    data-testid="reconnect-proxy-hostname"
                    className="hostName"
                  >
                    {hostName}
                  </span>
                  <span data-testid="proxy-connection-info">
                    {isWexTheme
                      ? t(
                          'ecp-fleet-proxies.proxyConnectedDescrWorkforce',
                          'was previously connected to Workforce Experience with the following information:'
                        )
                      : t(
                          'ecp-fleet-proxies.proxyConnectedDescrCommand',
                          'was previously connected to HP Command Center with following information:'
                        )}
                  </span>

                  <ProxyLink>
                    <div data-testid="reconnect-proxy-name">
                      {t('ecp-fleet-proxies.proxyname', 'Proxy Name:')}
                      <span className="hostName">{proxyNameLatest}</span>
                    </div>
                    <div data-testid="reconnect-proxy-desc">
                      {t('ecp-fleet-proxies.proxyDescription', 'Description:')}
                      <span className="hostName">{proxyDescription}</span>
                    </div>
                  </ProxyLink>
                  <span data-testid="proxy-select">
                    {t('ecp-fleet-proxies.proxySelect', 'Select')}
                  </span>
                  <span
                    data-testid="proxy-reconnect"
                    style={{ fontWeight: 'bold', padding: '5px' }}
                  >
                    {t('ecp-fleet-proxies.reconnect', 'Reconnect')}
                  </span>
                  <span data-testid="proxy-existing-info">
                    {t(
                      'ecp-fleet-proxies.proxyExistingInfo',
                      'to establish the connection with the existing information.'
                    )}
                  </span>
                  <div data-testid="proxy-after-reconnect">
                    {t(
                      'ecp-fleet-proxies.proxyAfterReconnect',
                      'You can Change the proxy name and description after you reconnect.'
                    )}
                  </div>
                </ReconectProxy>
              </>
            )}
            {!isProxyRegisteredAndConnected && (
              <div data-testid="successfully-connected-desc">
                {t('ecp-fleet-proxies.proxyConnectedDescr', 'Your proxy')}
                <span className="hostName">{hostName}</span>
                {t(
                  'ecp-fleet-proxies.proxyConnectedDescr2',
                  'is now connected. You can modify the proxy name and the description.'
                )}
              </div>
            )}
          </>
        )
      } else {
        return (
          <div data-test-id="Verified-proxy">
            {t('ecp-fleet-proxies.proxyConnectedDescr', 'Your proxy')}
            <span className="hostName">{hostName}</span>
            {t(
              'ecp-fleet-proxies.proxyVerifiedDescr',
              'has been verified and is ready for connection. Do you want to allow this connection?'
            )}
          </div>
        )
      }
    } else if (isWexTheme) {
      return t(
        'ecp-fleet-proxies.verifyModalDescriptionWorkforce',
        'In order to connect your proxy devices to the Workforce Experience Platform, you need to verify a code. Enter the verification code displayed on the Print Fleet Proxy installed PC.'
      )
    } else {
      return t(
        'ecp-fleet-proxies.verifyModalDescription',
        'In order to connect your proxy devices to the HP Command Center, you need to verify a code. Enter the verification code displayed on the HP Fleet Proxy installed PC.'
      )
    }
  }
  /* istanbul ignore next */
  return (
    <GenericModal
      dataTestId={'add-proxy-modal'}
      id={'add-proxy-modal'}
      onClose={onClose}
      show={showModal}
      customModalWidth={'612px'}
      customModalDescWidth={'327px'}
      header={{
        title: handleModalTitle(),
        dataTestId: 'add-proxy-modal-title'
      }}
      footer={{
        buttons: {
          cancel: {
            className: proxyConnected ? 'hideButton' : '',
            label: t('ecp-fleet-proxies.cancel', 'Cancel'),
            onClick: () => {
              onClose()
              setProxyCode('')
              setProxyVerified(false)
              setHasError(false)
            },
            dataTestId: 'add-proxy-cancel-btn'
          },
          proceed: {
            label: proceedBtnLabel(),
            onClick: handleContinueClick,
            disabled: buttonLoading,
            loading: buttonLoading,
            dataTestId: 'add-proxy-btn'
          }
        }
      }}
    >
      <>
        <AddProxyModalDescr data-testid="verify-proxy-desc">
          {headerDescription()}
        </AddProxyModalDescr>
        {!proxyVerified && !proxyConnected && (
          <>
            <AddProxyModalFooter data-testid="verify-proxy-code-block">
              <ThemeProvider shape="round">
                <TextBox
                  label={
                    <span data-testid="verify-proxy-label">
                      {t('ecp-fleet-proxies.verifyCodeLabel', 'Code')}
                    </span>
                  }
                  data-testid="enter-proxy-code"
                  error={hasError}
                  placeholder={t(
                    'ecp-fleet-proxies.verifyCodePlaceHolder',
                    'Enter a code'
                  )}
                  value={proxyCode}
                  required
                  separateLabel
                  helperText={handleHelperText()}
                  onChange={(value) => {
                    setProxyCode(value)
                  }}
                />
              </ThemeProvider>
            </AddProxyModalFooter>
            <AddProxyModalNote data-testid="add-proxy-modal-note">
              {t(
                'ecp-fleet-proxies.verifyModalNoteWorkforce',
                'Note: If you need a verification code, install'
              )}
              {isWexTheme ? (
                <>
                  <StyledLink
                    href={
                      authStack === 3
                        ? 'https://downloads.hpdaas.com/production/fleet-proxy-software/installer/latestversion/HP.FleetProxy.msi'
                        : pathname.includes('usdev')
                          ? 'https://downloads.hpdaas.com/master/fleet-proxy-software/installer/latestversion/HP.FleetProxy.dev.msi'
                          : 'https://downloads.hpdaas.com/staging/fleet-proxy-software/installer/latestversion/HP.FleetProxy.stage.msi'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Print Fleet Proxy
                  </StyledLink>
                </>
              ) : (
                <>
                  <DownloadLink
                    href={getDownloadUrl(authStack)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    HP Fleet Proxy
                  </DownloadLink>
                </>
              )}
              {t(
                'ecp-fleet-proxies.verifyModalNotecode',
                'on the Web Jetadmin PC and generate the code.'
              )}
            </AddProxyModalNote>
          </>
        )}
        {proxyConnected && proxyVerified && !isProxyRegisteredAndConnected && (
          <>
            {' '}
            <AddProxyModalFooter data-testid="add-proxy-block">
              <ThemeProvider shape="round">
                <TextBox
                  label={
                    <span data-testid="add-proxy-name-label">
                      {t('ecp-fleet-proxies.proxyName', 'Proxy Name')}
                    </span>
                  }
                  data-testid="add-proxy-name"
                  error={hasError}
                  placeholder={t('ecp-fleet-proxies.enterName', 'Enter a name')}
                  value={proxyNameLatest}
                  required
                  separateLabel
                  helperText={handleHelperText()}
                  onChange={(value) => {
                    setProxyNameLatest(value)
                  }}
                />
              </ThemeProvider>
            </AddProxyModalFooter>
            <AddProxyModalFooter>
              <ThemeProvider shape="round">
                <TextBox
                  data-testid="add-proxy-description"
                  label={
                    <span data-testid="add-proxy-desc-label">
                      {t('ecp-fleet-proxies.description', 'Description')}
                    </span>
                  }
                  placeholder={t(
                    'ecp-fleet-proxies.enterDescr',
                    'Enter description'
                  )}
                  separateLabel
                  value={proxyDescription}
                  onChange={(value) => {
                    setProxyDescription(value)
                  }}
                />
              </ThemeProvider>
            </AddProxyModalFooter>
          </>
        )}
      </>
    </GenericModal>
  )
}

export default AddProxyModal
