import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const ProxyContextualmenu = styled.div`
  .wex-theme {
    background: #ebebff !important;
  }
  @-webkit-keyframes animatebottom {
    from {
      bottom: -50px;
      opacity: 0;
    }
    to {
      bottom: 0px;
      opacity: 1;
    }
  }

  @keyframes animatebottom {
    from {
      bottom: -50px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }
  animation: 1s ease 0s 1 normal none running animatebottom;
  bottom: 0;
  display: block;
  margin: 0;
  position: fixed;
  z-index: 3;
  width: 100%;

  @media screen and (max-width: ${primitives.layout.breakpoint6}px) {
    left: 0;
    transition: left 500ms ease 0s;
  }
`
export const ProxyFooterContent = styled.div<{ isWexTheme?: boolean }>`
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  align-items: center;
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 0 1px;
  box-sizing: border-box;
  background: ${({ isWexTheme }) => (isWexTheme ? '#4D52E51A' : '#ebf9ff')};
  display: flex;
  justify-content: space-between;
  padding: 12px ${primitives.layout.size6}px;
  position: relative;
  left: -23px;
  label[data-focused='false'] {
    line-height: 16px !important;
  }
  label {
    line-height: 9px;
  }
  label + div > div[role='combobox'] {
    padding-left: 10px !important;
  }
  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    flex-direction: column-reverse;
    gap: $space-3;
  }
`
export const ProxyLeadingContent = styled.div`
  align-items: center;
  display: flex;
  gap: ${primitives.layout.size4}px;
  min-width: min-content;
  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    flex-direction: column;
    width: 100%;

    > * {
      width: 100%;
    }
  }
`

export const ProxyTrailingContent = styled.div`
  align-items: center;
  right: 30px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  gap: ${primitives.layout.size3}px;
  min-width: min-content;
  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    flex-direction: column;
    width: 100%;

    > * {
      width: 100%;
    }
  }
`
