import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Tag } from '@veneer/core'

export const ButtonWrapper = styled.div`
  margin-left: ${primitives.layout.size3}px;
`
export const Container = styled.div<{ isWexTheme?: boolean }>`
  .wex-tag {
    background-color: #4c52e51a !important;
    border-radius: 25px !important;
    > span {
      color: #2d2f86 !important;
    }
    svg {
      fill: #2d2f86 !important;
    }
  }
  display: flex;
  flex-direction: column;
  padding-top: ${primitives.layout.size4}px;
  padding-bottom: ${({ isWexTheme }) =>
    isWexTheme ? '60px' : `${primitives.layout.size6}px`};
  button[aria-label='Column Options'] {
    margin-left: 0 !important;
    align-items: baseline;
    background: none;
  }
  .proxy-table-br li[role='menuitem'] {
    margin: 0;
    :not(:last-of-type) {
      margin-bottom: 4px;
    }
  }
  .proxy-table-br ul {
    padding: calc(7px);
  }
  .proxy-table-br>div: first-child {
    height: unset;
  }
`

export const Content = styled.div`
  justify-content: space-between;
`
export const SearchForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-grow: 1;

  && {
    button {
      min-width: 0;
      margin-right: 0;
    }
    .fleetProxiesDisableHoverBackground:hover {
      background-color: transparent;
    }
  }
`
export const ActionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const ActionAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
export const ActionAreaFilterTags = styled.div`
  display: flex;
  width: 100%;
`
export const StyledTag = styled(Tag)`
  gap: 8px;
  background-color: rgba(2, 120, 171, 0.1) !important;
  display: flex;
  padding: 4px 12px;
  height: 28px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 5px 5px 5px 0;

  > span {
    font-size: 16px;
    color: #014667;
    overflow: inherit;
    text-overflow: inherit;
  }
  svg {
    fill: #014667;
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
  }
  [role='button']:hover {
    background-color: transparent;
  }
`
export const StyledForm = styled.form`
  width: 100%;
`
