import React, { useMemo, useState } from 'react'
import GenericModal from '../GenericModal'
import { TextBox, ThemeProvider } from '@veneer/core'
import { useShellRootContext } from '../../../contexts/ShellRoot'
import { UpdateProxyModalFooter, UpdateProxyModalFooterDescr } from './style'
const UpdateProxyModal = ({
  modalTitle,
  onClose,
  onUpdateProxy,
  showModal,
  isLoading,
  selectedProxyName,
  selectedProxyDescr,
  proxies
}) => {
  const [proxyNameLatest, setProxyNameLatest] =
    useState<string>(selectedProxyName)
  const [proxyDescrLatest, setProxyDescrLatest] =
    useState<string>(selectedProxyDescr)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isProxyAvailable, setIsProxyAvailable] = useState<boolean>(false)
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}

  const handleHelperText = () => {
    if (hasError && proxyNameLatest === '') {
      return t('ecp-fleet-proxies.nameRequired', 'Proxy name is required.')
    }
    if (hasError && isProxyAvailable) {
      return t(
        'ecp-fleet-proxies.proxyNameAvailable',
        "The proxy name you've entered is already in use. Enter a different name."
      )
    }
  }

  const checkIsProxyAvailable = (value: string) => {
    const newProxyName = value.trim().toLowerCase()
    const isNameEmpty = newProxyName === ''

    const existingProxy = proxies.find(
      (it) => it.proxyName.toLowerCase() === newProxyName
    )
    if (isNameEmpty || (existingProxy && selectedProxyName !== value)) {
      setHasError(true)
      setIsProxyAvailable(existingProxy !== undefined)
    } else {
      setHasError(false)
      setIsProxyAvailable(false)
    }
  }
  const handleUpdateClick = () => {
    const newProxyName = proxyNameLatest.trim().toLowerCase()
    const isNameEmpty = newProxyName === ''

    const existingProxy = proxies.find(
      (it) => it.proxyName.toLowerCase() === newProxyName
    )
    if (
      isNameEmpty ||
      (existingProxy && selectedProxyName !== proxyNameLatest)
    ) {
      setHasError(true)
      setIsProxyAvailable(existingProxy !== undefined)
    } else {
      onUpdateProxy(proxyNameLatest, proxyDescrLatest)
      setHasError(false)
      setIsProxyAvailable(false)
    }
  }

  const hasChanges = useMemo(() => {
    return (
      proxyNameLatest !== selectedProxyName ||
      proxyDescrLatest !== selectedProxyDescr
    )
  }, [proxyDescrLatest, proxyNameLatest, selectedProxyDescr, selectedProxyName])

  return (
    <GenericModal
      dataTestId={'update-proxy-modal'}
      id={'update-proxy-modal'}
      onClose={onClose}
      show={showModal}
      customModalWidth={'612px'}
      customModalDescWidth={'327px'}
      header={{
        title: modalTitle,
        dataTestId: 'update-proxy-modal-title'
      }}
      footer={{
        buttons: {
          cancel: {
            label: t('ecp-fleet-proxies.cancel', 'Cancel'),
            onClick: onClose,
            disabled: isLoading,
            dataTestId: 'update-proxy-cancel-btn'
          },
          proceed: {
            label: t('ecp-fleet-proxies.save', 'Save'),
            onClick: () => {
              handleUpdateClick()
            },
            disabled: isLoading || !hasChanges,
            loading: isLoading,
            dataTestId: 'update-proxy-btn'
          }
        }
      }}
    >
      <UpdateProxyModalFooterDescr data-testid="update-proxy-desc">
        {t(
          'ecp-fleet-proxies.editProxyDescr',
          'You can modify the proxy name and the description.'
        )}
      </UpdateProxyModalFooterDescr>
      <UpdateProxyModalFooter data-testid="update-proxy-block">
        <ThemeProvider shape="round">
          <TextBox
            label={
              <span data-testid="update-proxy-label">
                {t('ecp-fleet-proxies.proxyName', 'Proxy Name')}
              </span>
            }
            data-testid="update-proxy-name"
            value={proxyNameLatest}
            placeholder={t('ecp-fleet-proxies.enterName', 'Enter a name')}
            error={hasError}
            required
            separateLabel
            helperText={handleHelperText()}
            onChange={(value) => {
              setProxyNameLatest(value)
              checkIsProxyAvailable(value)
            }}
          />
        </ThemeProvider>
      </UpdateProxyModalFooter>
      <UpdateProxyModalFooter>
        <ThemeProvider shape="round">
          <TextBox
            label={
              <span data-testid="update-proxy-description">
                {t('ecp-fleet-proxies.description', 'Description')}
              </span>
            }
            data-testid="update-proxy-description"
            placeholder={t('ecp-fleet-proxies.enterDescr', 'Enter description')}
            separateLabel
            value={proxyDescrLatest}
            onChange={(value) => {
              setProxyDescrLatest(value)
            }}
          />
        </ThemeProvider>
      </UpdateProxyModalFooter>
    </GenericModal>
  )
}

export default UpdateProxyModal
